import BaseApi from '../baseApi';

export default class CustomerExchangeBanksApi extends BaseApi {
  constructor(obj){
      obj = obj || {};
      super(obj);
  }

  find(filters) {
    return new Promise((resolve, reject) => {
      return this.api
        .get('/customers/exchange-banks', { params: filters })
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  }

  updateStatus(idCustomer, idExchangeBank, statusUpdateDto) {
    idCustomer = idCustomer || {};
    idExchangeBank = idExchangeBank || {};
    statusUpdateDto = statusUpdateDto || {};

    return new Promise((resolve, reject) => {
      return this.api
        .patch(`/customers/${idCustomer}/bank/${idExchangeBank}/update-status`, statusUpdateDto)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  }
}

