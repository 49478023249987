import BaseApi from '../baseApi';

export default class DashboardApi extends BaseApi {
  constructor(obj){
      obj = obj || {};
      super(obj);
  }

  getCustomerCount(filters) {
    return new Promise((resolve, reject) => {
      return this.api
        .get('/dashboard/customers/count', { params: filters })
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  }

  getOperations(filters) {
    return new Promise((resolve, reject) => {
      return this.api
        .get('/dashboard/operations', { params: filters })
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  }

  getCustomerExchangeBanksDistinctCustomer(filters) {
    return new Promise((resolve, reject) => {
      return this.api
        .get('/dashboard/customers/exchange-banks/distinct-customer', { params: filters })
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  }

  getEmailCount(filters) {
    return new Promise((resolve, reject) => {
      return this.api
        .get('/dashboard/email/count', { params: filters })
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  }
}

