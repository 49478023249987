import BaseApi from '../baseApi';

export default class CurrencyApi extends BaseApi {
  constructor(obj){
      obj = obj || {};
      super(obj);
  }

  findAll() {
    return new Promise((resolve, reject) => {
      return this.api
        .get(`/currencies`)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  }

  add(currency) {
    currency = currency || {};

    return new Promise((resolve, reject) => {
      return this.api
        .post('/currencies', currency)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  }

  update(currency) {
    currency = currency || {};

    return new Promise((resolve, reject) => {
      return this.api
        .put(`/currencies/${currency.id}`, currency)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  }

  findId(id) {
    id = id || {};

    return new Promise((resolve, reject) => {
      return this.api
        .get(`/currencies/${id}`)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  }
}
