import Vue from 'vue';

import { OPTIONS_STORE_ACCESS } from '../vuex/module/access';
import { ACCESS_VALUES } from '../../../common/constants/global';

import store from '../vuex';
/* eslint-disable */
async function applyAccessMethod(el, binding, vnode) {
  if (!binding.value) {
    return;
  }

  let access;
  const data = store.getters[OPTIONS_STORE_ACCESS.GETTERS.GET_ACCESS];

  if (data?.length == 0) {
    _hideElement(el);
    return;
  }

  try {
    access = data.filter((a) => binding.value.includes(a.code));

    access = reduceAccess(access);
  } catch {
    access = undefined;
  }

  if (!access || access.value == ACCESS_VALUES.DISABLED) {
    _disableElement(el, vnode);
    return;
  }

  if (access.value == ACCESS_VALUES.HIDDEN) {
    _hideElement(el);
  }
}

function reduceAccess(accessList) {
  return accessList.reduce((acc, cur) => {
    acc.value = acc.value || ACCESS_VALUES.HIDDEN;

    if (acc.value == ACCESS_VALUES.ENABLED || acc.value == cur.value) {
      return acc;
    }

    if (cur.value == ACCESS_VALUES.ENABLED) {
      acc.value = ACCESS_VALUES.ENABLED;
    }

    if (cur.value == ACCESS_VALUES.DISABLED) {
      acc.value = ACCESS_VALUES.DISABLED;
    }

    return acc;
  }, {});
}

function elementIsInAMenu(el) {
  const vueInstance = el.__vue__;
  const isInListGroup = (vueInstance.$el?.className || '').includes('v-list-group');

  if (!vueInstance) return false;

  return vueInstance.isInMenu || vueInstance.isInNav || vueInstance.tabsBar || isInListGroup;
}

function _hideElement(el) {
  if (elementIsInAMenu(el)) {
    el.style.display = 'none';
  } else {
    el.style.visibility = 'hidden';
  }
}

function _disableElement(el, vnode) {
  if (elementIsInAMenu(el)) {
    el.style.display = 'none';

    return;
  }

  if (el.__vue__) {
    let silent = Vue.config.silent;
    Vue.config.silent = true;
    vnode.context.$set(el.__vue__._props, 'disabled', true);
    Vue.config.silent = silent;
  }
}

export default {
  bind: applyAccessMethod,
  update: applyAccessMethod
};
