<template>
  <v-row>
    <v-col
      cols="12"
      md=""
    >
      <h1>Documentos complementares</h1>
      <v-row class="pt-3">
        <v-col
          class="py-0"
          cols="12"
          md="12"
        >
          <v-divider class="py-3" />
        </v-col>
        <template v-for="(document, i) in operation.customerOperationalDocuments">
          <v-col
            class="py-0"
            cols="12"
            md="3"
            :key="'type' + i"
          >
            <span class="document-description"> {{ getDescriptionDocument(document) }} </span>
          </v-col>
          <v-col
            class="py-0 text-right"
            cols="12"
            md="3"
            :key="'actions' + i"
          >
            <v-tooltip top>
              <template #activator="{ on, attrs }">
                <v-btn
                  icon
                  text
                  @click="downloadDocument(document)"
                  v-bind="attrs"
                  v-on="on"
                >
                  <i class="far fa-file-download" />
                </v-btn>
              </template>
              <span>Baixar</span>
            </v-tooltip>

            <v-tooltip top>
              <template #activator="{ on, attrs }">
                <v-btn
                  icon
                  text
                  @click="removeDocument(i)"
                  v-bind="attrs"
                  v-on="on"
                >
                  <i class="far fa-trash" />
                </v-btn>
              </template>
              <span>Excluir</span>
            </v-tooltip>
          </v-col>
        </template>
        <v-col md="12">
          <v-btn
            class="btn-secondary float-right"
            @click="openAddDocumentModal"
            text
          >
            <i class="far fa-search" />
            Buscar no cliente
          </v-btn>
        </v-col>
      </v-row>
    </v-col>

    <!-- Add operational document Modal -->
    <v-dialog
      persistent
      eager
      v-model="addDocumentModal"
      max-width="500px"
    >
      <v-card>
        <v-card-title>
          <h1 class="titulo-modal">Documento complementar</h1>
        </v-card-title>
        <v-divider />
        <v-card-text class="py-5">
          <v-row class="py-5">
            <v-col
              cols="12"
              md="12"
            >
              <v-form
                id="add-currency"
                ref="form"
              >
                <v-row>
                  <v-col
                    class="py-0"
                    cols="12"
                    md="12"
                  >
                    <v-autocomplete
                      label="Documentos"
                      :items="documents"
                      :item-text="item => getDescriptionDocument(item)"
                      item-value="id"
                      v-model="selectedDocument"
                      return-object
                      clearable
                      dense
                      outlined
                      v-disabled-icon-focus
                    />
                  </v-col>
                </v-row>
              </v-form>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-col class="text-right">
            <v-btn
              text
              class="btn-primary mr-2"
              @click="insertDocument"
            >
              Salvar
            </v-btn>
            <v-btn
              text
              class="btn-secondary"
              @click="addDocumentModal = false"
            >
              Fechar
            </v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
// APIs
import CustomerOperationalDocumentsApi from "@/api/customer/customer-operational-documents-api";
import CustomerApi from "@/api/customer/customer-api";

// Models
import CustomerModel from "@/model/customer-model";

// Mixins
import mixinMessage from "@/mixin/mixin-message";

export default {
  name: "CustomerOperationalDocuments",
  mixins: [mixinMessage],
  components: {},
  model: {
    prop: "operationProp",
    event: "onChange",
  },
  props: {
    operationProp: {
      type: Object,
      default: () => {}
    },
  },
  inject: ['appData'],
  data() {
    return {
      operation: this.operationProp,
      documents: [],
      addDocumentModal: false,
      selectedDocument: undefined,
      customerApi: new CustomerApi(this.appData.currentUser),
      apiCustomerOperationalDocuments: new CustomerOperationalDocumentsApi(this.appData.currentUser),
    };
  },
  mounted() { },
  watch: {
    "operation.customer"() {
      if (this.operation.customer == undefined) {
        this.customer = new CustomerModel();

        return;
      }

      this.getCustomer();
    },
  },
  methods: {
    insertDocument() {
      if (!this.selectedDocument) {
        this.addDocumentModal = false;
        
        return;
      }
      
      this.operation.customerOperationalDocuments.push(this.selectedDocument);
      this.addDocumentModal = false;
    },
    removeDocument(index) {
      this.operation.customerOperationalDocuments.splice(index, 1);
    },
    getCustomer() {
      this.customerApi
        .findId(this.operation.customer.id)
        .then((response) => {
          this.documents = new CustomerModel(response.data).operationalDocuments;
        })
        .catch((error) => {
          this.sendMessage(
            (error.response && error.response.data.mensagem) || error,
            "error"
          );
        });
    },
    openAddDocumentModal() {
      this.selectedDocument = undefined;
      this.addDocumentModal = true;
    },
    downloadDocument(operationDocument) {
      this.apiCustomerOperationalDocuments
        .download(operationDocument.id)
        .then((response) => {
          let link = document.createElement("a");

          link.href = URL.createObjectURL(response.data);
          link.setAttribute(
            "download",
            `${operationDocument.operationalDocumentType.description}`
          );
          link.click();

          URL.revokeObjectURL(link.href);

          this.sendMessage("Baixando anexo", "success");
        })
        .catch(() => {
          this.sendMessage("Falha ao baixar o anexo!", "error");
        });
    },

    getDescriptionDocument(item) {
      let description;

      if (!item?.description) {
        let index = this.documents.findIndex(d => d.id === item.id);

        description = `Compl. ${index+1}`;
      } else {
        description = item.description;
      }

      return `${item?.operationalDocumentType?.description} - ${description}`;
    }
  },
};
</script>

<style scoped>
.document-description {
  font-size: 1.5em;
  font-weight: 300;
}
</style>