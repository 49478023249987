<template>
  <v-row>
    <v-col
      cols="12"
      md="12"
    >
      <div class="box">
        <h1>Contas p/ pagamento <InfoTooltip label="Mesma titularidade" /></h1>
        <v-divider class="py-3" />
        <v-row
          v-for="(bank, index) in customer.paymentAccounts"
          :key="index"
        >
          <v-col
            class="py-0"
            cols="12"
            md="4"
          >
            <v-autocomplete
              label="Banco"
              :item-text="itemTextBank"
              clearable
              item-value="code"
              dense
              outlined
              v-disabled-icon-focus
              v-model="bank.bank"
              :items="paymentBankList"
            />
          </v-col>
          <v-col
            class="py-0"
            cols="12"
            md="3"
          >
            <v-text-field
              v-model="bank.agency"
              outlined
              dense
              label="Agência"
            />
          </v-col>
          <v-col
            class="py-0"
            cols="12"
            md="3"
          >
            <v-text-field
              v-model="bank.account"
              outlined
              dense
              label="Conta"
            />
          </v-col>
          <v-col
            class="py-0"
            cols="12"
            md="1"
          >
            <v-text-field
              v-model="bank.digit"
              outlined
              dense
              label="Digito"
            />
          </v-col>
          <v-col
            cols="12"
            md="1"
            v-if="customer.paymentAccounts.length >= 2"
            class="py-0"
          >
            <v-btn
              icon
              class="float-right"
              @click="removeAccount(index)"
              text
            >
              <i class="far fa-trash" />
            </v-btn>
          </v-col>
        </v-row>
        <v-divider />
        <v-row class="py-0">
          <v-col class="pt-5">
            <v-btn
              class="btn-secondary float-right"
              @click="insertAccount"
              text
            >
              <i class="far fa-plus" /> Adicionar outro
            </v-btn>
          </v-col>
        </v-row>
      </div>
    </v-col>
  </v-row>
</template>

<script>
// Models
import CustomerPaymentAccountModel from "@/model/customer-payment-account-model";
import ExchangeBankModel from "@/model/exchange-bank-model";

// APIs
import ExchangeBankApi from "@/api/exchangeBank/exchange-bank-api";

// Cache
import bankCache from "../../../../common/cache/bank-cache";

// Mixins
import mixinMessage from "@/mixin/mixin-message";

// Components
import InfoTooltip from '@/components/comum/InfoTooltip';

export default {
  name: "CustomerPaymentAccounts",
  mixins: [ mixinMessage ],
  components: { InfoTooltip },
  model: {
    prop: "customerProp",
    event: "onChange",
  },
  props: {
    customerProp: {
      type: Object,
    },
  },
  watch: {
    customer() {
      this.$emit("onChange", this.customer);
    },
  },
  inject: ['appData'],
  data() {
    return {
      customer: this.customerProp,
      exchangeBanks: [],
      listOfBanks: [],
      exchangeBankApi: new ExchangeBankApi(this.appData.currentUser),
    };
  },
  async mounted() {
    this.getExchangeBanks();

    if (this.customer.paymentAccounts.length == 0) {
      this.customer.paymentAccounts = [new CustomerPaymentAccountModel()];
    }

    this.listOfBanks = await bankCache.getBankList();
  },
  computed: {
    paymentBankList(){
      let paymentBankCodes = this.exchangeBanks.filter(b => b.paymentAccount && b.linkedBank).map(b=> parseInt(b.linkedBank));
      let paymentBanks = this.listOfBanks.filter(b => paymentBankCodes.includes(b.code));

      return paymentBanks;
    },
    itemTextBank() {
      return (item) => !item.code ? '000 - ' + item.name.toUpperCase() : item.code + ' - ' + item.name.toUpperCase();
    }
  },
  methods: {
    getExchangeBanks() {
      this.exchangeBankApi
        .findAll()
        .then((response) => {
          this.exchangeBanks = response.data.map((b) => new ExchangeBankModel(b));
        })
        .catch((error) => {
          this.sendMessage(
            (error.response && error.response.data.mensagem) || error,
            "error"
          );
        });
    },

    insertAccount() {
      this.customer.paymentAccounts.push(new CustomerPaymentAccountModel());
    },
    removeAccount(index) {
      if (this.customer.paymentAccounts.length > 1) {
        this.customer.paymentAccounts.splice(index, 1);
      }
    },
  },
};
</script>

<style>
</style>