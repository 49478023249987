const PERSON_TYPE = {
  PHYSICAL: { 'id': 1, 'description': 'Pessoa Física' },
  LEGAL: { 'id': 2, 'description': 'Pessoa Jurídica' },
  INTERNATIONAL_AGENT: { 'id': 3, 'description': 'Agente Internacional' },
};

const PERSON_TYPE_LIST = Object.values(PERSON_TYPE);

const REGISTER_TYPE = {
  EXCHANGE: { id: 1, description: 'Pessoa Física Câmbio Turismo' },
  BASIC_REMITTANCE: { id: 2, description: 'Pessoa Física Básico' },
  COMPLETE_REMITTANCE: { id: 3, description: 'Pessoa Física Completo' },
  LEGAL_PERSON: { id: 4, description: 'Pessoa Jurídica' }
};

const REGISTER_TYPE_LIST = Object.values(REGISTER_TYPE);

const OPERATION_TYPE = {
  REMMITANCE: {
    'id': '1',
    'description': 'Remessa',
  },
  EXCHANGE: {
    'id': '2',
    'description': 'Turismo',
  },
  BOTH: {
    'id': '3',
    'description': 'Ambas',
  },
}

const OPERATION_TYPE_LIST = Object.values(OPERATION_TYPE);

const ENTITY = {
  CUSTOMER: { id: 1, description: 'Cliente' },
  OPERATION: { id: 2, description: 'Operação' },
  BANK: { id: 3, description: 'Banco' },
  STORES: { id: 4, description: 'Lojas' },
  USER: { id: 5, description: 'Usuário' },
  OPERATION_NATURE: { id: 6, description: 'Natureza da Operação' },
}

const MUTATIONS = {
  CREATE: { id: 1, description: 'Criação' },
  UPDATE: { id: 2, description: 'Atualização' },
  DELETE: { id: 3, description: 'Deleção' },
}

// eM DESENVOLVIMENTO
const ENTITIES = [
  ENTITY.CUSTOMER,
  ENTITY.OPERATION,
  ENTITY.BANK,
  ENTITY.STORES,
  ENTITY.USER,
  ENTITY.OPERATION_NATURE
]

const ADDRESS_TYPE = {
  RESIDENTIAL: { 'id': 1, 'description': 'Residencial' },
  DELIVERY: { 'id': 2, 'description': 'Entrega' },
  PROFESSIONAL: { 'id': 3, 'description': 'Trabalho' },
};

const ADDRESS_TYPE_LIST = Object.values(ADDRESS_TYPE);

const PROFILES = {
  ADMINISTRATIVE: { id: 1, description: 'Administrador' },
  CORBAN: { id: 2, description: 'Corban' },
  CUSTOMER: { id: 1, description: 'Cliente' },
}

const PROFILES_LIST = Object.values(PROFILES);

const SYSTEM_ACCESS_PROFILES = {
  ADMINISTRATIVE: { id: 1, description: 'Admin' },
  CORBAN: { id: 2, description: 'Corban' },
}

module.exports = {
  PERSON_TYPE,
  PERSON_TYPE_LIST,
  REGISTER_TYPE,
  REGISTER_TYPE_LIST,
  OPERATION_TYPE,
  OPERATION_TYPE_LIST,
  ADDRESS_TYPE,
  ADDRESS_TYPE_LIST,
  ENTITIES,
  ENTITY,
  MUTATIONS,
  PROFILES,
  PROFILES_LIST,
  SYSTEM_ACCESS_PROFILES,
}