import CorrespondentAdministrativeModel from './correspondent-administrative-model';
import CorrespondentPartnerShareholderModel from './correspondent-partner-shareholder-model';
import ExchangeBankModel from './exchange-bank-model';
import CorrespondentSpreadModel from './correspondent-spread-model';
import Person from '../../../common/models/Person';

export default class CorrespondentModel extends Person {
  constructor(obj) {
    obj = obj || {};
    super(obj);

    let partnersShareholders = obj.partnersShareholders?.map(partnersShareholders => new CorrespondentPartnerShareholderModel(partnersShareholders));

    this.id = obj.id;
    this.cpfCnpj = obj.cpfCnpj;
    this.name = obj.name;
    this.fantasyName = obj.fantasyName;
    this.cellphone = obj.cellphone;
    this.landline = obj.landline;
    this.bank = parseInt(obj.bank) || undefined;
    this.agency = obj.agency;
    this.account = obj.account;
    this.digit = obj.digit;
    this.cep = obj.cep;
    this.country = obj.country;
    this.state = obj.state;
    this.city = obj.city;
    this.neighborhood = obj.neighborhood;
    this.street = obj.street;
    this.number = obj.number;
    this.complement = obj.complement;
    this.internationalCep = !!obj.internationalCep;
    this.firstRegisterEmail = obj.firstRegisterEmail;
    this.secondRegisterEmail = obj.secondRegisterEmail;
    this.thirdRegisterEmail = obj.thirdRegisterEmail;
    this.firstOperationEmail = obj.firstOperationEmail;
    this.secondOperationEmail = obj.secondOperationEmail;
    this.thirdOperationEmail = obj.thirdOperationEmail;
    this.firstDocumentationEmail = obj.firstDocumentationEmail;
    this.secondDocumentationEmail = obj.secondDocumentationEmail;
    this.thirdDocumentationEmail = obj.thirdDocumentationEmail;
    this.firstFinancialEmail = obj.firstFinancialEmail;
    this.secondFinancialEmail = obj.secondFinancialEmail;
    this.thirdFinancialEmail = obj.thirdFinancialEmail;
    this.sendExternalLink = false;
    this.accessEmail = obj.accessEmail;
    this.pix = obj.pix;
    this.administrative = new CorrespondentAdministrativeModel(obj.administrative);
    this.partnersShareholders = partnersShareholders?.length ? partnersShareholders : [];
    this.remittanceBanks = obj.remittanceBanks?.map(rb => new ExchangeBankModel(rb)) || [];
    this.tourismBanks = obj.tourismBanks?.map(tb => new ExchangeBankModel(tb)) || [];
    this.spreads = obj.spreads?.map(s => new CorrespondentSpreadModel(s)) || [];
    this.externalId = obj.externalId;
    this.createdAt = obj.createdAt;
    this.updatedAt = obj.updatedAt;
  }
}