<template>
  <v-row>
    <v-col
      cols="12"
      md="12"
    >
      <v-row>
        <v-col
          cols="12"
          lg="3"
          sm="6"
          offset-lg="9"
          offset-sm="6"
          class="pa-0 pr-3"
        >
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Pesquisar"
            single-line
            hide-details
            class="pt-0 mt-0"
          />
        </v-col>
        <v-col
          v-if="!localOperations.length"
          cols="12"
          md="12"
        >
          <EmptyTable
            title="Nenhum registro encontrado"
            icon="far fa-hand-holding-usd"
            subtitle="Não há operações em aberto para o dia de hoje."
          />
        </v-col>
        <v-col
          v-else
          cols="12"
          md="12"
        >
          <v-card class="card-cambio pt-1">
            <v-data-table
              :headers="columns"
              :items="localOperations"
              :search="search"
              sort-by="id"
              sort-asc
              :items-per-page="paginationDefault"
              :custom-filter="customFilter"
              height="calc(100dvh - 420px)"
              item-key="sketchId"
              :expanded.sync="expanded"
              show-expand
              @click:row="toggleRowExpansion"
              fixed-header
            >
              <template #[`item.exchangeType.description`]="{ item }">
                <template v-if="item.exchangeType?.id == 1 && item.exchangeBuy">
                  <span>Remessa de Compra</span>
                </template>
                <template v-else-if="item.exchangeType?.id == 1">
                  <span>Remessa de Venda</span>
                </template>
                <template v-else-if="item.exchangeType?.id == 2 && item.exchangeBuy">
                  <span>Compra de M.E.</span>
                </template>
                <template v-else>
                  <span>Venda de M.E.</span>
                </template>
              </template>

              <template #[`item.updatedAt`]="{ item }">
                <span>{{ item.updatedAt | date }}</span>
              </template>

              <template #[`item.customer.customerNameOrCompanyName`]="{ item }">
                <span
                  class="customer-name"
                  v-if="item.customer.isPJ()"
                >{{ item.customer.companyName }}</span>
                <span
                  class="customer-name"
                  v-else
                >{{ item.customer.name }}
                </span>
                <v-tooltip top>
                  <template #activator="{ on, attrs }">
                    <v-btn
                      icon
                      color="primary"
                      @click="goToCustomer(item.customer.id)"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <i class="far fa-eye" />
                    </v-btn>
                  </template>
                  <span>Visualizar cliente</span>
                </v-tooltip>
              </template>

              <template #[`item.exchangeBuy`]="{ item }">
                <v-chip
                  v-if="item.exchangeBuy"
                  small
                  label
                  color="teal lighten-4"
                >
                  Compra
                </v-chip>
                <v-chip
                  v-else
                  small
                  label
                  color="red lighten-4"
                >
                  Venda
                </v-chip>
              </template>

              <template #[`item.amount`]="{ item }">
                <span>{{ item.currency.code }} - {{ item.amount | formatCurrency(item.currency.code) }}</span>
              </template>

              <template #[`item.customerRate`]="{ item }">
                <span>{{ item.customerRate | formatCurrency('BRL', 5) }}</span>
              </template>

              <template #[`item.totalValue`]="{ item }">
                <span>{{ item | formatCurrencyTotalValue }}</span>
              </template>

              <template #[`item.actions`]="{ item }">
                <v-menu
                  offset-y
                  offset-overflow
                  left
                  slide-y
                  @click.stop
                >
                  <template #activator="{ on: menu }">
                    <v-tooltip
                      top
                    >
                      <template #activator="{ on: tooltip, attrs }">
                        <v-btn
                          icon
                          text
                          v-bind="attrs"
                          v-on="{ ...tooltip, ...menu }"
                          @click.stop
                        >
                          <i class="far fa-ellipsis-v" />
                        </v-btn>
                      </template>
                      <span>Opções</span>
                    </v-tooltip>
                  </template>

                  <v-list>
                    <v-list-item
                      @click="editOperationSketch(item)"
                    >
                      <v-list-item-title><i class="far fa-pencil pr-2 menu-buttons" />Editar</v-list-item-title>
                    </v-list-item>

                    <v-list-item
                      @click="getBankMessage(item)"
                    >
                      <v-list-item-title><i class="far fa-copy pr-2 menu-buttons" />Copiar Simulação do Banco</v-list-item-title>
                    </v-list-item>

                    <v-list-item
                      @click="getCustomerMessage(item)"
                    >
                      <v-list-item-title><i class="far fa-copy pr-2 menu-buttons" />Copiar Simulação do Cliente</v-list-item-title>
                    </v-list-item>

                    <v-list-item
                      @click="openFinishOperationDialog(item)"
                      v-administrative-access
                    >
                      <v-list-item-title><i class="far fa-check pr-2 menu-buttons" />Concluir</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </template>

              <template #expanded-item="{ item }">
                <td :colspan="columns.length + 1">
                  <v-card class="box card-cambio my-5">
                    <v-row no-gutters>
                      <v-col class="d-flex flex-column">
                        <span class="expand-title">N° da OP</span>
                        <span class="expand-value">{{ item.sketchId }}</span>
                      </v-col>

                      <v-col class="d-flex flex-column">
                        <span class="expand-title">Spread</span>
                        <span class="expand-value">{{ item | formatSpread }}</span>
                      </v-col>

                      <v-col class="d-flex flex-column">
                        <span class="expand-title">IOF</span>
                        <p class="expand-value">{{ item.iofValue || 0 | formatCurrency('BRL') }} ({{ item.iof || 0 | formatNumber }} %)</p>
                      </v-col>
                      
                      <v-col class="d-flex flex-column">
                        <span class="expand-title">IR</span>
                        <p class="expand-value">{{ item.irValue || 0 | formatCurrency('BRL') }} ({{ item.ir || 0 | formatNumber }} %)</p>
                      </v-col>

                      <v-col class="d-flex flex-column">
                        <span class="expand-title">Tarifa</span>
                        <p class="expand-value">{{ item.exchangeContractCostBrl || 0 | formatCurrency('BRL') }}</p>
                      </v-col>

                      <v-col class="d-flex flex-column">
                        <span class="expand-title">Agente</span>
                        <p class="expand-value">{{ item.agent.name }}</p>
                      </v-col>

                      <v-col class="d-flex flex-column">
                        <span class="expand-title">SPOT Previsto</span>
                        <span class="expand-value">{{ item | formatCurrencyQuotation }}</span>
                      </v-col>

                      <v-col>
                        <v-row no-gutters>
                          <v-col class="d-flex flex-column">
                            <span class="expand-title">SPOT Atual</span>
                            <span class="expand-value">{{ actualCurrencyQuotation | formatActualCurrencyQuotation }}</span>
                          </v-col>
                        </v-row>

                        <v-row no-gutters>
                          <v-col>
                            <span class="texto-atualizando">{{ `Atualizando em ${item.counterCurrencyQuotation}s` }}</span>
                          </v-col>
                        </v-row>
                      </v-col>

                      <v-col class="d-flex flex-column">
                        <span class="expand-title">Diferença do SPOT</span>
                        <span
                          :class="
                            getDifferenceCurrencyQuotation(actualCurrencyQuotation, item.currencyQuotation) >= 0
                              ? 'expand-value-green'
                              : 'expand-value-red'"
                        >
                          {{ item | formatDifferenceCurrencyQuotation(actualCurrencyQuotation) }}
                        </span>
                      </v-col>

                      <v-col class="d-flex flex-column">
                        <span class="expand-title">Natureza</span>
                        <span>
                          {{ item.nature.exibitionName }}
                        </span>
                      </v-col>

                      <v-col cols="12" col="12">
                        <BankAccount :account="item.account" :isInternational="!item.exchangeBuy" />
                      </v-col>
                    </v-row>
                  </v-card>
                </td>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </v-col>

    <!-- Close Operation Dialog -->
    <v-dialog
      persistent
      v-model="finishOperationDialog"
      max-width="500px"
    >
      <v-card>
        <v-card-title>
          <h1 class="titulo-modal">Métodos de pagamento</h1>
        </v-card-title>
        <v-divider />
        <v-card-text class="py-5">
          <v-row class="py-5">
            <v-col
              cols="12"
              md="12"
            >
              <v-form
                id="add-method"
                ref="form"
              >
                <v-row>
                  <v-col
                    class="text-left"
                    cols="12"
                    md="6"
                  >
                    <span class="color-text-dark">COTAÇÃO</span>
                  </v-col>
                  <v-col
                    class="text-right"
                    cols="12"
                    md="6"
                  >
                    <span class="color-text-dark">{{ operation | formatCurrencyQuotation }}</span>
                  </v-col>
                  <v-col
                    class="text-left"
                    cols="12"
                    md="6"
                  >
                    <span class="color-text-dark">M.E.</span>
                  </v-col>
                  <v-col
                    class="text-right"
                    cols="12"
                    md="6"
                  >
                    <span class="color-text-dark">{{ operation.currency.name }}</span>
                  </v-col>
                  <v-col
                    class="text-left"
                    cols="12"
                    md="6"
                  >
                    <span class="color-text-dark">VALOR M.E.</span>
                  </v-col>
                  <v-col
                    class="text-right"
                    cols="12"
                    md="6"
                  >
                    <span class="color-text-dark">{{ operation | formatCurrencyAmount }}</span>
                  </v-col>
                  <v-col
                    class="text-left"
                    cols="12"
                    md="6"
                  >
                    <span class="color-text-dark">TAXA DO CLIENTE</span>
                  </v-col>
                  <v-col
                    class="text-right"
                    cols="12"
                    md="6"
                  >
                    <span class="color-text-dark">{{ operation | formatCurrencyCustomerRate }}</span>
                  </v-col>
                  <v-col
                    class="text-left"
                    cols="12"
                    md="6"
                  >
                    <span class="color-text-dark">VET</span>
                  </v-col>
                  <v-col
                    class="text-right"
                    cols="12"
                    md="6"
                  >
                    <span class="color-text-dark">{{ operation | formatVet }}</span>
                  </v-col>
                  <v-col
                    class="text-left"
                    cols="12"
                    md="6"
                  >
                    <span class="color-text-dark">TOTAL</span>
                  </v-col>
                  <v-col
                    class="text-right"
                    cols="12"
                    md="6"
                  >
                    <span class="color-text-dark">{{ operation | formatCurrencyTotalValue }}</span>
                  </v-col>
                  <v-col
                    cols="12"
                    md="12"
                  >
                    <MoneyField
                      label="Taxa do Banco"
                      prefix="R$ "
                      v-model="operation.bankRate"
                      :precision="6"
                    />
                  </v-col>
                </v-row>
              </v-form>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-col class="text-right">
            <v-btn
              text
              class="btn-primary mr-2"
              @click="finishOperation"
            >
              Salvar
            </v-btn>
            <v-btn
              text
              class="btn-tertiary"
              @click="finishOperationDialog = false"
            >
              Cancelar
            </v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
// Api
import OperationApi from "@/api/operation/operation-api";
import SketchApi from "@/api/general/sketch-api";
import TriggerApi from "@/api/generic/trigger-api";
import RateApi from "@/api/general/rate-api";

// Models
import OperationModel from "@/model/operation-model";
import TriggerModel from "@/model/generic/trigger-model";

// Mixins
import mixinMessage from "@/mixin/mixin-message";
import mixinDataTableFilters from "@/mixin/mixin-data-table-filters";
import mixinExpandedDataTable from "@/mixin/mixin-data-table-expanded";

// Utils
import dateUtils from "../../../../common/utils/date";
import numberUtils from "../../../../common/utils/number";
import operationMessagesTemplate from "@/utils/operationMessagesTemplate";
import { customFilterDataTable } from '@/utils/dataTable';

// Constants
import { OPERATION_TYPE } from "../../../../common/constants/generic/types";
import { SEND_EMAIL_TRIGGER_CODES } from "../../../../common/constants/generic/triggers";
import { OPERATION_WORKFLOW } from "../../../../common/workflows/operationWorkflow";

// Components
import MoneyField from "@/components/comum/MoneyField.vue";
import EmptyTable from "@/components/comum/EmptyTable.vue";
import { PAGINATION_DEFAULT } from '../../constants/general-constants';
import BankAccount from "../../components/form-operation/OperationBankAccount.vue";

// Mixpanel
import mixpanel from "mixpanel-browser";
export default {
  name: "OpenOperationsReport",
  mixins: [mixinMessage, mixinDataTableFilters, mixinExpandedDataTable],
  components: { MoneyField, EmptyTable, BankAccount },
  inject: ['appData'],
  props: {
    operations: { type: Array, default: () => { return []; }, },
  },
  data() {
    return {
      search: "",
      localOperations: [],
      columns: [
        { text: "Tipo", value: "exchangeType.description", align: "left", sortable: true, width: "180px" },
        { text: "Data", value: "updatedAt", align: "left", sortable: true, width: "180px" },
        { text: "Cpf/Cnpj", value: "customer.cpfCnpj", align: "left", sortable: true, width: "160px" },
        { text: "Cliente / Razão social", value: "customer.customerNameOrCompanyName", align: "left", sortable: true, width: "500px" },
        { text: "Tipo", value: "exchangeBuy", align: "center", sortable: true, width: "10px" },
        { text: "Liquidação", value: "liquidation.description", align: "center", sortable: true, width: '180px' },
        { text: "Valor M.E.", value: "amount", align: "left", sortable: true, width: '180px' },
        { text: "Tx. cliente.", value: "customerRate", align: "left", sortable: true, width: '120px' },
        { text: "Valor Total", value: "totalValue", align: "left", sortable: true, width: '120px' },
        { text: "", value: "actions", align: "right", sortable: false, width: '10px' },
      ],
      finishOperationDialog: false,
      operation: new OperationModel(),
      triggerApi: new TriggerApi(this.appData.currentUser),
      paginationDefault: PAGINATION_DEFAULT,
      actualCurrencyQuotation: 0,
      sketchApi: new SketchApi(this.appData.currentUser),
      rateApi: new RateApi(this.appData.currentUser),
      operationApi: new OperationApi(this.appData.currentUser),
    };
  },
  watch: {
    operations() {
      this.localOperations = this.operations;
    }
  },
  mounted() {
    this.localOperations = this.operations;
  },
  beforeDestroy() {
    this.localOperations.forEach(item => {
      if (item.intervalCurrencyQuotation) {
        this.stopGetCurrengyQuotation(item);
      }
    });
  },
  computed: {},
  filters: {
    formatNumber(value) {
      return new Intl.NumberFormat('pt-BR').format(value);
    },
    date(value) {
      return dateUtils.maskDateAndHourIso(value);
    },
    formatCurrency(value, code, precison) {
      code = code || 'USD';

      return numberUtils.formatCurrency(code, value, precison);
    },
    formatCurrencyAmount(operation) {
      let code = operation.currency.code || 'USD';

      return numberUtils.formatCurrency(code, operation.amount);
    },
    formatCurrencyTotalValue(operation) {
      return numberUtils.formatCurrency('BRL', operation.totalValue);
    },
    formatCurrencyCustomerRate(operation) {
      return numberUtils.formatCurrency('BRL', operation.customerRate, 6);
    },
    formatVet(operation) {
      return numberUtils.formatCurrency('BRL', operation.vet, 6);
    },
    formatCurrencyQuotation(operation) {
      return numberUtils.formatCurrency('BRL', operation.currencyQuotation, 6);
    },
    formatSpread(operation) {
      return numberUtils.formatNumber(operation.spread, 2) + '%';
    },
    formatActualCurrencyQuotation(actualCurrencyQuotation) {
      return numberUtils.formatCurrency('BRL', actualCurrencyQuotation, 6);
    },
    formatDifferenceCurrencyQuotation(operation, actualCurrencyQuotation) {
      return numberUtils.formatCurrency('BRL', actualCurrencyQuotation - operation.currencyQuotation, 6);
    },
  },
  methods: {
    getBankMessage(operation) {
      navigator.clipboard.writeText(operationMessagesTemplate.getBankMessage(operation));
    },

    getCustomerMessage(operation) {
      navigator.clipboard.writeText(operationMessagesTemplate.getCustomerMessage(operation));
    },

    openFinishOperationDialog(item) {
      this.operation = item;
      this.finishOperationDialog = true;
    },

    finishOperation() {
      this.operation.status = OPERATION_WORKFLOW.EM_ANALISE;
      this.operation.effectiveOperationDate = new Date().toISOString();

      this.operationApi
        .add(this.operation)
        .then((response) => {
          let documentIds = this.operation.documents.map(d => d.id);
          let customerOperationalDocumentIds = this.operation.customerOperationalDocuments.map(d => d.id);

          if (this.operation.exchangeType.id == OPERATION_TYPE.REMMITANCE.id) {
            this.operationFinishedTrigger(response.data, documentIds, customerOperationalDocumentIds);
            this.operationIndicationAcknowledgementTrigger(response.data);
          }

          let index = this.localOperations.findIndex(o => o.sketchId == this.operation.sketchId);
          this.sketchApi.remove(this.operation.sketchId);
          this.localOperations.splice(index, 1);
          this.operation = new OperationModel();

          this.finishOperationDialog = false;
        })
        .catch((error) => {
          this.sendMessage(
            (error.response && error.response.data.mensagem) || error,
            "error"
          );
        });
    },

    editOperationSketch(operation) {
      this.$router
        .push({
          name: "FormEditOperation",
          params: {
            id: operation.sketchId,
          },
        })
        .catch(() => { });
    },
    operationFinishedTrigger(operation, documentIds, customerOperationalDocumentIds) {
      let trigger = new TriggerModel({
        code: SEND_EMAIL_TRIGGER_CODES.OPERACAO_FECHAMENTO,
        idOperation: operation.id,
        idCustomer: operation.customer.id,
        documentIds: documentIds,
        customerOperationalDocumentIds: customerOperationalDocumentIds,
      });

      this.triggerApi
        .trigger(trigger)
        .then(() => { })
        .catch((error) => {
          this.sendMessage(
            (error.response && error.response.data.mensagem) || error,
            "error"
          );
        });
    },

    operationIndicationAcknowledgementTrigger(operation) {
      let trigger = new TriggerModel({
        code: SEND_EMAIL_TRIGGER_CODES.OPERACAO_AGRADECIMENTO_INDICACAO,
        idOperation: operation.id,
      });

      this.triggerApi
        .trigger(trigger)
        .then(() => { })
        .catch((error) => {
          this.sendMessage(
            (error.response && error.response.data.mensagem) || error,
            "error"
          );
        });
    },

    customFilter(value, search, item) {
      const getExchangeType = (item) => {
        if (item.exchangeType?.id == 1 && item.exchangeBuy) return 'Remessa de Compra';

        if (item.exchangeType?.id == 1) return 'Remessa de Venda';

        if (item.exchangeType?.id == 2 && item.exchangeBuy) return 'Compra de M.E.';

        return 'Venda de M.E.';
      };

      let customFields = [
        { name: "currency.name" },
        { name: "updatedAt" },
        { name: "bank.fantasyName" },
        { name: "nature.exibitionName" },
      ];

      customFields.push({
        name: 'customer',
        value: (item) => item.customer.isPJ() ? item.customer.companyName : item.customer.name
      });

      customFields.push({
        name: 'exchangeType.description',
        value: (item) => getExchangeType(item)
      });

      customFields.push({
        name: 'amount',
        value: (item) => item.amount.toString()
      });

      customFields.push({
        name: 'customerRate',
        value: (item) => item.customerRate.toString()
      });

      customFields.push({
        name: 'totalValue',
        value: (item) => item.totalValue.toString()
      });

      return customFilterDataTable(value, search, item, customFields);
    },

    getDifferenceCurrencyQuotation(atualCurrencyQuotation, currencyQuotation) {
      return atualCurrencyQuotation - currencyQuotation;
    },

    getCurrencyQuotation(item) {
      this.rateApi
        .findCommercial({ currencies: item.currency.code })
        .then((response) => {
          this.actualCurrencyQuotation = response.data[0].ask;
        });
    },

    startGetCurrengyQuotation(item) {
      const ELEVEN_SECONDS_IN_MILLISECONDS = 11000;
      const ONE_SECOND_IN_MILLISECONDS = 1000;

      if (!item.intervalCounterCurrencyQuotation) {
        item.intervalCurrencyQuotation = setInterval(() => {
          this.getCurrencyQuotation(item);
        }, ELEVEN_SECONDS_IN_MILLISECONDS);
      }

      if (!item.intervalCounterCurrencyQuotation) {
        item.intervalCounterCurrencyQuotation = setInterval(() => {
          if (item.counterCurrencyQuotation > 0)
            item.counterCurrencyQuotation--;
          else
            item.counterCurrencyQuotation = 10;
        }, ONE_SECOND_IN_MILLISECONDS);
      }
    },

    stopGetCurrengyQuotation(item) {
      clearInterval(item.intervalCurrencyQuotation);
      clearInterval(item.intervalCounterCurrencyQuotation);
      item.intervalCurrencyQuotation = null;
      item.intervalCounterCurrencyQuotation = null;
      item.counterCurrencyQuotation = 10;
    },

    toggleRowExpansion(item, slot) {
      this.clickRow(item, slot);

      if (!slot.isExpanded) {
        this.getCurrencyQuotation(item);
        this.startGetCurrengyQuotation(item);

        return;
      }

      this.stopGetCurrengyQuotation(item);
    },

    goToCustomer(id) {
      mixpanel.track("click", {
        button_name: "edit_customer",
      });
      this.$router
        .push({
          name: "FormEditCustomer",
          params: {
            id: id,
          },
        })
        .catch(() => { });
    },
  },
};
</script>

<style lang="scss" scoped>
#add-method {
  color: black;
}

.theme--dark .color-text-dark {
  color: white;
}

.menu-buttons {
  color: var(--v-primary-base);
}

.texto-atualizando {
  font-size: 12px;
  color: $red;
}
</style>
