export default class CustomerExchangeBankFiltersModel {
  constructor(obj) {
    obj = obj || {};

    this.startApprovalDate = obj.startApprovalDate;
    this.endApprovalDate = obj.endApprovalDate;
    this.customerRegisterStatusList = obj.customerRegisterStatusList;
    this.customerExchangeBankStatusList = obj.customerExchangeBankStatusList;
    this.idCustomer = obj.idCustomer;
    this.exchangeBankList = obj.exchangeBankList;
    this.idExchangeType = obj.idExchangeType;
    this.corbanId = obj.corbanId;
  }
}
