export default class CustomerPartnerShareholderModel {
  constructor(obj) {
    obj = obj || {};
    this.id = obj.id;
    this.name = obj.name;
    this.cpfCnpj = obj.cpfCnpj;
    this.share = obj.share;
    this.isLegalPerson = !!obj.isLegalPerson;
    this.email = obj.email;
    this.cellphone = obj.cellphone;
    this.createdAt = obj.createdAt;
    this.updatedAt = obj.updatedAt;
    this.linkedCustomerId = obj.linkedCustomerId;
  }
}