<template>
  <div>
    <div>
      <v-btn
        class="btn-secondary float-right"
        text
        @click="drawer = true"
      >
        <v-icon>mdi-filter-variant</v-icon>
        Filtrar
      </v-btn>
    </div>

    <v-navigation-drawer
      v-model="drawer"
      bottom
      fixed
      temporary
      right
      :width="500"
    >
      <v-list nav>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="text-h6">Filtro</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-list-item>
        <v-list-item-content>
          <v-form id="filters-form">
            <v-row>
              <v-col
                v-if="fullFilter"
                cols="12"
                class="pb-0"
              >
                <Datepicker
                  v-model="localFilters.startDate"
                  label="Data de início"
                  v-disabled-icon-focus
                />
              </v-col>
              <v-col
                v-if="fullFilter"
                cols="12"
                class="py-0"
              >
                <Datepicker
                  v-model="localFilters.endDate"
                  label="Data de fim"
                  v-disabled-icon-focus
                />
              </v-col>

              <v-col
                v-if="fullFilter"
                cols="12"
                class="py-0"
              >
                <v-autocomplete
                  label="Tipo"
                  :items="listExchangeType"
                  item-text="label"
                  v-model="localFilters.exchangeBuy"
                  clearable
                  dense
                  outlined
                  v-disabled-icon-focus
                />
              </v-col>

              <v-col
                cols="12"
                class="py-0"
              >
                <v-autocomplete
                  label="Moeda"
                  v-model="localFilters.currencyId"
                  :items="currencyList"
                  item-text="name"
                  item-value="id"
                  clearable
                  dense
                  outlined
                  v-disabled-icon-focus
                />
              </v-col>
              <v-col
                v-if="!fullFilter"
                cols="12"
                class="py-0"
              >
                <v-autocomplete
                  label="Período"
                  v-model="selectedDate"
                  :items="filterDateItems"
                  item-text="label"
                  item-value="value"
                  dense
                  outlined
                />
              </v-col>
              <v-col
                cols="12"
                class="py-0"
              >
                <v-autocomplete
                  label="Banco"
                  v-model="localFilters.bankList"
                  :items="bankList"
                  item-text="fantasyName"
                  item-value="id"
                  clearable
                  dense
                  outlined
                  multiple
                  v-disabled-icon-focus
                  :search-input.sync="searchInput"
                  @change="searchInput=''"
                />
              </v-col>

              <v-col
                v-if="fullFilter"
                cols="12"
                class="py-0"
              >
                <v-autocomplete
                  label="Corban"
                  :items="agentList"
                  item-text="name"
                  item-value="id"
                  v-model="localFilters.agentId"
                  clearable
                  dense
                  outlined
                  v-disabled-icon-focus
                />
              </v-col>

              <v-col
                cols="12"
                class="py-0"
              >
                <v-autocomplete
                  label="Tipo de Pessoa"
                  v-model="localFilters.idPersonType"
                  :items="personTypeList"
                  item-text="description"
                  item-value="id"
                  clearable
                  dense
                  outlined
                  v-disabled-icon-focus
                />
              </v-col>

              <v-col
                v-if="fullFilter"
                cols="12"
                class="py-0"
              >
                <v-text-field
                  outlined
                  dense
                  v-model="localFilters.cpfCnpj"
                  label="Cpf/Cnpj"
                  v-mask="localFilters.cpfCnpj?.length > 14 ? '##.###.###/####-##' : '###.###.###-##'"
                />
              </v-col>

              <v-col
                v-if="filterOperationStatusId"
                cols="12"
                class="py-0"
              >
                <v-autocomplete
                  label="Status"
                  v-model="localFilters.statusList"
                  :items="operationStatusList"
                  item-text="description"
                  item-value="id"
                  clearable
                  dense
                  outlined
                  multiple
                  v-disabled-icon-focus
                />
              </v-col>
            </v-row>
          </v-form>
        </v-list-item-content>
      </v-list-item>

      <template #append>
        <v-list-item>
          <v-list-item-content>
            <v-row>
              <v-col
                cols="6"
                class="d-flex justify-center"
              >
                <v-btn
                  class="btn-tertiary"
                  width="100%"
                  text
                  @click="resetFilters"
                >
                  Limpar
                </v-btn>
              </v-col>
              <v-col
                cols="6"
                class="d-flex justify-center"
              >
                <v-btn
                  class="btn-primary"
                  text
                  width="100%"
                  @click="filter"
                >
                  Filtrar
                </v-btn>
              </v-col>
            </v-row>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-navigation-drawer>
  </div>
</template>

<script>

// Api
import CorbanApi from "@/api/correspondent/correspondent-api";

// Models
import OperationFiltersModel from '../../model/operation-filters-model';
import CorrespondentModel from "@/model/correspondent-model";

// Constants
import { FILTER_ITEMS, FILTER_TYPE } from '@/constants/filters-constants';
import { PERSON_TYPE } from '@/constants/general-constants';
import { OPERATION_WORKFLOW_LIST } from "../../../../common/workflows/operationWorkflow";

// Mixins
import mixinMessage from '@/mixin/mixin-message';

// Components
import Datepicker from "@/components/comum/Datepicker.vue";

export default {
  name: 'OperationFilterDrawer',

  mixins: [mixinMessage],

  components: { Datepicker },

  model: {
    prop: 'filters',
    event: 'onChange'
  },

  props: {
    filters: {
      type: OperationFiltersModel,
      default: () => {}
    },
    bankList: {
      type: Array,
      default: () => []
    },
    currencyList: {
      type: Array,
      default: () => []
    },
    fullFilter: {
      type: Boolean,
      default: true
    },
    statusList: {
      type: Array,
      default: () => []
    },
    exchangeTypeId: {
      type: String,
      default: ''
    },
    filterThisMonth: {
      type: Boolean,
      default: false
    },
    filterOperationStatusId: {
      type: Boolean,
      default: false
    }
  },

  watch: {
      selectedDate(newValue) {
        if (newValue && newValue.START_DATE && newValue.END_DATE) {
          this.localFilters.startDate = newValue.START_DATE;
          this.localFilters.endDate = newValue.END_DATE;
        }
      }
    },

  computed: {
    localFilters: {
      get() {
        return this.filters;
      },
      set(value) {
        this.$emit('onChange', value);
      }
    }
  },

  inject: ['appData'],
  data() {
    return {
      drawer: false,
      filterDateItems: FILTER_ITEMS,
      personTypeList: PERSON_TYPE,
      selectedDate: FILTER_TYPE.THIS_MONTH,
      listExchangeType: [{ value: true, label: "Compra" }, { value: false, label: "Venda" }],
      agentList: [],
      corbanApi: new CorbanApi(this.appData.currentUser),
      operationStatusList: OPERATION_WORKFLOW_LIST,
      searchInput: ''
    };
  },

  mounted() {
    this.createDefaultFilter();

    if (this.fullFilter) 
      this.getAgentList();
  },

  methods: {
    createDefaultFilter() {
      this.selectedDate = this.filterThisMonth
        ? FILTER_TYPE.THIS_MONTH
        : FILTER_TYPE.TODAY;

      this.localFilters = new OperationFiltersModel({
        statusList: this.statusList.length ? this.statusList : undefined,
        exchangeTypeId: this.exchangeTypeId ? this.exchangeTypeId : undefined,
        startDate: this.selectedDate.START_DATE,
        endDate: this.selectedDate.END_DATE 
      });
    },

    filter() {
      this.drawer = false;
      this.$emit('filter');
    },

    resetFilters() {
      this.createDefaultFilter();
      this.$emit('filter');
      this.drawer = false;
    },

    getAgentList() {
      this.corbanApi
        .findAll()
        .then((response) => {
          this.agentList = response.data.map((c) => new CorrespondentModel(c));
        })
        .catch((error) => {
          this.sendMessage(
            (error.response && error.response.data.mensagem) || error,
            "error"
          );
        });
    },
  }
};
</script>
