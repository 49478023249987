<template>
  <v-app>
    <Menu
      v-model="isOpenSimulator"
      v-if="
        this.$router.currentRoute.name != 'Login' &&
        this.$router.currentRoute.name != 'FormEditCustomerExternalAcess' &&
        this.$router.currentRoute.name != 'FormEditCorrespondentExternalAcess' &&
        this.$router.currentRoute.name != 'FileView' &&
        this.$router.currentRoute.name != 'FormCustomerFinally' &&
        this.$router.currentRoute.name != 'CustomerDocumentsExternalAccess' &&
        this.$router.currentRoute.name != 'OperationDocumentsExternalAccess'
      "
      :key="key"
    />
    <v-snackbar
      v-model="snackbar"
      :vertical="false"
      :timeout="timeout"
      top="top"
      :color="type"
      elevation="0"
      right
    >
      {{ message }}
      <template #action="{ attrs }">
        <v-btn
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          OK
        </v-btn>
      </template>
    </v-snackbar>
    <OperationSimulator
      v-model="isOpenSimulator"
      v-if="
        this.$router.currentRoute.meta.requireAuth &&
        isOpenSimulator &&
        this.$router.currentRoute.name != 'FileView'
      "
    />
    <v-main>
      <router-view />

      <!-- Loading -->
      <v-dialog
        v-model="loading"
        persistent
        width="200"
        height="200"
        class="pa-5"
      >
        <v-card class="pa-5">
          <v-card-text class="d-flex justify-center align-center text-center flex-column">
            <v-progress-circular
              :size="80"
              indeterminate
              color="primary"
              class="m-0"
            ></v-progress-circular>
            <h2 class="pt-2">Carregando</h2>
          </v-card-text>
        </v-card>
      </v-dialog>

      <div class="footer">
        <div class="by">
          &copy; {{ new Date().getFullYear() }} - Powered by DeepRocket Tecnologia LTDA
        </div>
        <div by="version">{{ version }}</div>
      </div>
    </v-main>
  </v-app>
</template>

<script>
//Constants
import { OPTIONS_STORE_ACCESS } from './vuex/module/access';

// Components
import Menu from '@/components/menu/Menu';
import OperationSimulator from '@/components/comum/OperationSimulator';

// Mixins
import mixinAuthorization from '@/mixin/mixin-authorization';
import mixinMessage from "@/mixin/mixin-message";

// Storage
import utilsStorage from '@/utils/storage';

import { mapGetters } from 'vuex/dist/vuex.common.js';

export default {
  name: 'App',
  mixins: [mixinAuthorization, mixinMessage],
  components: {
    Menu,
    OperationSimulator
  },

  provide() {
    const appData = {};

    /* Necessário para que a propriedade seja reativa */
    Object.defineProperty(appData, "currentUser", {
      get: () => this.user,
    });

    return {
      appData,
    };
  },

  data() {
    return {
      isOpenSimulator: false,
      version: `${process.env.VUE_APP_NAME} - ${process.env.VUE_APP_APP_VERSION}`,
      message: '',
      snackbar: false,
      timeout: 5000,
      type: '',
      key: 0,
      loading: false,
    };
  },
  watch: {
    getAccess: {
      handler() {
        this.key++;
      },
      deep: true
    }
  },
  async mounted() {
    if(this.user?.id){
      await this.$store.dispatch(OPTIONS_STORE_ACCESS.ACTIONS.FIND_ACCESS, this.user);
      this.key++;
    }

    this.verifyTheme();
    this.$eventBus.$on('snackbar-message', this.showSnackbar);
    this.$eventBus.$on('show-loading', this.showLoading);
    this.$eventBus.$on('token-changed', this.getUser);
  },
  computed: {
    ...mapGetters({
      getAccess: OPTIONS_STORE_ACCESS.GETTERS.GET_ACCESS
    })
  },
  methods: {
    verifyTheme() {
      this.$vuetify.theme.dark = utilsStorage.getThemeSettings();
    },
    showLoading(loading) {
      this.loading = loading;
    },
    showSnackbar(snackbar) {
      this.type = snackbar.type;
      this.message = snackbar.message;
      this.snackbar = true;
      this.timeout = snackbar.timeout || 5000;
    },
  }
};
</script>
<style lang="scss">
.versao {
  display: block;
  position: fixed;
  bottom: 0;
  right: 0;
  padding: 0px 15px 15px 5px;
}

.v-tabs .v-tab {
  text-transform: none;
}

.footer {
  font-size: 0.7rem;
  display: flex;
  justify-content: space-between;
  padding: 20px;
}
</style>

