export default class SketchModel {
  constructor(obj) {
    obj = obj || {};

    this.id = obj.id;
    this.description = obj.description;
    this.content = obj.content;
    this.typeId = obj.typeId;
    this.agentId = obj.agentId;
    this.customerId = obj.customerId;
    this.bankId = obj.bankId;
    this.currencyId = obj.currencyId;
    this.userId = obj.userId;
    this.isUserCorban = obj.isUserCorban;
    this.updatedAt = obj.updatedAt;
  }
}