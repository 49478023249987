import { PROFILES } from '../../../common/constants/generic/types';

async function applyAccessMethod(el, binding, vnode) {
  let user = vnode.context?.appData?.currentUser;

  if (user && user.profileId != PROFILES.ADMINISTRATIVE.id) {
    el.style.display = 'none';
  }
}

export default {
  bind: applyAccessMethod,
  update: applyAccessMethod
};
