import axios from 'axios';
import utilsStorage from '@/utils/storage';

async function loadConfig() {
  try {
    const response = await fetch('/config.json');

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    return await response.json();
  } catch (error) {
    console.error('Erro ao carregar a configuração:', error);

    return {}; // Retorne um objeto vazio ou algum fallback
  }
}

const appConfig = await loadConfig();

export default class BaseApi {
  constructor(obj) {
    obj = obj || {};

    this._routePrefix = obj.correspondentId ? '/corban' : '';
    this.api = axios.create({ baseURL: appConfig.urlApi + this._routePrefix });

    this._createInterceptors();
  }

  _createInterceptors() {
    this.api.interceptors.request.use(async (config) => {
      var token = utilsStorage.getTokenOnStorage();
      config.headers.Authorization = token;
  
      return config;
    });
  
    this.api.interceptors.response.use(
      (response) => {
        if (response.status === 401 && response.config.url !== '/login') {
          utilsStorage.removeAuth();
  
          setTimeout(() => {
            window.location.href = '/login';
          }, 1000);
        }
  
        return response;
      },
      (error) => {
        if (!error.response) {
          return Promise.reject(
            `Não foi possível se conectar com o servidor. Se essa mensagem persistir, por favor, contate o suporte técnico.`
          );
        }
  
        if (error.response.status === 401 && error.response.config.url !== '/login') {
          utilsStorage.removeAuth();
  
          setTimeout(() => {
            window.location.href = '/login';
          }, 3000);
  
          return Promise.reject(
            `Seu login de acesso expirou, efetue o login novamente para acessar o sistema.`
          );
        }
  
        return Promise.reject(error);
      }
    );
  }
}