import CustomerModel from '@/model/customer-model';
import ProfileModel from '@/model/profile-model';
import AccessProfileModel from './access/access-profile-model';

export default class UserModel {
    constructor(obj) {
        obj = obj || {};
        this.id = obj.id;
        this.name = obj.name;
        this.email = obj.email && obj.email.toString().toLowerCase();
        this.password = obj.password;
        this.profileId = obj.profileId;
        this.profile = new ProfileModel(obj.profile);
        this.customerId = obj.customerId;
        this.customer = new CustomerModel(obj.customer);
        this.correspondentId = obj.correspondentId;
        this.createdAt = obj.createdAt;
        this.updatedAt = obj.updatedAt;
        this.dateInactivation = obj.dateInactivation;
        this.accessProfile = obj.accessProfile?.map((a) => new AccessProfileModel(a)) || [];
        this.firstAccess = obj.firstAccess;
    }

    validModel(){
        return !!(this.name && this.email && this.password && this.password.length >= 6 && this.accessProfile.length);
    }
    validModelUpdate() {
        return !!(this.name && this.email && this.accessProfile.length);
    }
}