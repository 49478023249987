import { render, staticRenderFns } from "./CustomersAuthorizationControl.vue?vue&type=template&id=afeb8486&scoped=true"
import script from "./CustomersAuthorizationControl.vue?vue&type=script&lang=js"
export * from "./CustomersAuthorizationControl.vue?vue&type=script&lang=js"
import style0 from "./CustomersAuthorizationControl.vue?vue&type=style&index=0&id=afeb8486&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "afeb8486",
  null
  
)

export default component.exports