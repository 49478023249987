import BaseApi from '../baseApi';

export default class OperationNatureApi extends BaseApi {
  constructor(obj){
      obj = obj || {};
      super(obj);
  }

  add(nature) {
    nature = nature || {};

    return new Promise((resolve, reject) => {
      return this.api
        .post('/operation-natures', nature)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  }

  update(nature) {
    nature = nature || {};

    return new Promise((resolve, reject) => {
      return this.api
        .put(`/operation-natures/${nature.id}`, nature)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  }

  findAll() {
    return new Promise((resolve, reject) => {
      return this.api
        .get('/operation-natures')
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  }

  findId(id) {
    id = id || {};

    return new Promise((resolve, reject) => {
      return this.api
        .get(`/operation-natures/${id}`)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  }
}
