import DocumentTypeModel from './document-type-model';

export default class CustomerDocumentModel {
  constructor(obj) {
    obj = obj || {};

    this.id = obj.id;
    this.documentType = new DocumentTypeModel(obj.documentType);
    this.document = obj.document;
    this.path = obj.path;
    this.fileName = obj.fileName;
    this.description = obj.description;
    this.required = obj.required || false;
    this.createdAt = obj.createdAt;
    this.updatedAt = obj.updatedAt;

  }
}